import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../Component/Common/Header/Header";
import Sidebar from "../../Component/Common/Sidebar/Sidebar";
import ReportList from "./ServiceReportList";
import { Button, DatePicker, Form, Select, Tag } from "antd";
import { psApiCalling } from "../../Component/API/Index";
import dayjs from "dayjs";
import moment from "moment";
import Table from "../../Component/Table/Table";
export default function ServiceReport() {
  const [oid, setOid] = useState(window.location.pathname.split("/")[2]);
  const [clientName, setClientName] = useState(
    window.location.pathname.split("/")[3]
  );
  const [leads, setLeads] = useState([]);
  const [site, setSite] = useState([
    {
      label: "All",
      value: "All",
    },
  ]);
  const [tech, setTech] = useState([
    {
      label: "All",
      value: "All",
    },
  ]);
  const [clientCode, setClientCode] = useState(null);

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedSite, setSelectedSite] = useState("All");
  const [selectedTech, setSelectedTech] = useState("All");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [type, setType] = useState("Technician");
  const [typeValue, setTypeValue] = useState("All");
  const [total, setTotal] = useState("0");
  const [pending, setPending] = useState("0");
  const [completed, setCompleted] = useState("0");
  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    // {
    //   title: "Service Date",

    //   key: "service_date",
    //   render: (item, record) => (
    //     <Tag color="#0be881">
    //       {moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
    //     </Tag>
    //   ),
    // },
    {
      title: "S. No.",
      render: (_, __, index) => index + 1,
      key: "sno",
    },
    {
      title: "Customer",
      render: (item, record) => <Tag color="#3c40c6">{item.clientName}</Tag>,
      key: "customer",
    },
    {
      title: "Tech. Name",
      render: (item, record) => <Tag color="#1abc9c">{item.techName}</Tag>,
      key: "tech_name",
    },
    {
      title: "Total",
      render: (item, record) => <Tag color="#00d8d6">{item.total}</Tag>,
      key: "total",
    },
    // {
    //   title: "Service Type",
    //   render: (item, record) => <Tag color="#3498db">{item.cname}</Tag>,
    //   key: "service_day",
    // },
    {
      title: "Pending",
      render: (item, record) => <Tag color="#e74c3c">{item.pending}</Tag>,
      key: "subject",
    },
    {
      title: "Completed",
      render: (item, record) => <Tag color="#e67e22">{item.completed}</Tag>,
      key: "completed",
    },

    {
      title: "View Details",
      key: "action",
      render: (item, record) => (
        <>
          <Button
            type="primary"
            href={
              "/services/" +
              item.cid +
              "/" +
              item.service_guy +
              "/" +
              item.site_id
            }
            style={{ background: "#0be881" }}
          >
            View Service
          </Button>
        </>
      ),
    },
  ]);

  const getLeadsList = () => {
    let params = { action: "GET_ALL_LEADS" };
    psApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        let arr = res.map((item) => {
          return {
            label: item.data.client_name,
            value: item.data.id,
          };
        });
        console.log("array=====>", arr);
        setLeads(arr);
      }
    });
  };

  const getClientSite = (v) => {
    let params = { action: "GET_ALL_LOCATION" };
    psApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        console.log("res=====>", res);
        let filtered = res.filter((item) => item.lead_id === v);
        let arr = filtered.map((item) => {
          return {
            id: item.aid,
            value: item.address,
          };
        });
        let finalArr = [{ label: "All", value: "All" }, ...arr];

        setSite(finalArr);
      }
    });
  };

  const getServiceGuyList = () => {
    let params = { action: "GET_SERVICE_GUY_LIST" };
    psApiCalling(params).then((res) => {
      console.log("arr======>", res);
      if (Array.isArray) {
        setTech(res);
      }
    });
  };

  const onChangeStart = (value, string) => {
    setStartDate(dayjs(string, "DD-MM-YYYY"));
  };
  const onChangeEnd = (value, string) => {
    setEndDate(dayjs(string, "DD-MM-YYYY"));
  };

  const getResult = () => {
    if (type === null) {
      alert("Please select type");
    } else {
      let params = {
        action: "GET_CLIENT_REPORT",
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        type: type,
        typeValue: typeValue,
      };
      getServiceCount();
      psApiCalling(params).then((res) => {
        if (Array.isArray(res)) {
          setData(res);
        } else {
          setData([]);
        }
      });
    }
  };

  const getServiceCount = () => {
    let params = {
      action: "GET_SERVICE_COUNT",
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
      type: type,
      typeValue: typeValue,
    };
    psApiCalling(params).then((res) => {
      console.log("res===>", res);
      if (res.status === "success") {
        setTotal(res.total);
        setPending(res.pending);
        setCompleted(res.completed);
      }
    });
  };

  useEffect(() => {
    getLeadsList();
  }, []);
  useEffect(() => {
    getServiceGuyList();
  }, []);

  return (
    <Container fluid className={"mainContainer"}>
      <Row>
        <Col md={2} style={{ padding: "0px", margin: "0px" }}>
          <Sidebar to={window.location.pathname} />
        </Col>
        <Col md={10}>
          <Header />
          <Container style={{ marginTop: "30px" }}>
            <Row>
              <Col>
                <Form layout={"vertical"}>
                  <Form.Item label={"From Date"}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      value={startDate}
                      onChange={onChangeStart}
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Col>
                <Form layout={"vertical"}>
                  <Form.Item label={"To Date"}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      value={endDate}
                      onChange={onChangeEnd}
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Col>
                <Form layout="vertical">
                  <Form.Item label={"Type"}>
                    <Select
                      value={type}
                      options={[
                        {
                          label: "Technician",
                          value: "Technician",
                        },
                        {
                          label: "Customer",
                          value: "Customer",
                        },
                        {
                          label: "All",
                          value: "All",
                        },
                      ]}
                      showSearch={true}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(v) => {
                        setType(v);
                        // getClientSite(v);
                      }}
                    />
                  </Form.Item>
                </Form>
              </Col>
              {type === "Customer" ? (
                <Col>
                  <Form layout="vertical">
                    <Form.Item label={"Client"}>
                      <Select
                        options={leads}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(v) => {
                          setSelectedClient(v);
                          getClientSite(v);
                          setTypeValue(v);
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Col>
              ) : null}

              {type === "Technician" ? (
                <Col>
                  <Form layout="vertical">
                    <Form.Item label={"Technician"}>
                      <Select
                        options={tech}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(v) => {
                          setSelectedTech(v);
                          setTypeValue(v);
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Col>
              ) : null}

              <Col>
                <Form layout="vertical">
                  <Form.Item style={{ marginTop: "30px" }}>
                    <Button onClick={() => getResult()}>Apply Filter</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={3}></Col>
              <Col md={2} style={{ padding: "10px" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    background: "#8e44ad",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        fontSize: "25px",
                        color: "#FFFFFF",
                        textAlign: "center",
                      }}
                    >
                      Total
                    </h3>
                    <p style={{ color: "#FFFFFF", textAlign: "center" }}>
                      {total}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={2} style={{ padding: "10px" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    background: "#27ae60",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        fontSize: "25px",
                        color: "#FFFFFF",
                        textAlign: "center",
                      }}
                    >
                      Completed
                    </h3>
                    <p style={{ color: "#FFFFFF", textAlign: "center" }}>
                      {completed}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={2} style={{ padding: "10px" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    background: "#f1c40f",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        fontSize: "25px",
                        color: "#FFFFFF",
                        textAlign: "center",
                      }}
                    >
                      Pending
                    </h3>
                    <center>
                      <p style={{ color: "#FFFFFF", textAlign: "center" }}>
                        {pending}
                      </p>
                    </center>
                  </div>
                </div>
              </Col>
              <Col md={3}></Col>
            </Row>
          </Container>

          <Container fluid>
            <Table col={col} data={data} />
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
