import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../Component/Common/Header/Header";
import Sidebar from "../../Component/Common/Sidebar/Sidebar";
import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Spin } from "antd";

import { Button, DatePicker, Form, Select, Tag } from "antd";
import { psApiCalling } from "../../Component/API/Index";
import dayjs from "dayjs";
import moment from "moment";
import Table from "../../Component/Table/Table";
export default function ServiceReport() {
  const [cid, setCode] = useState(window.location.pathname.split("/")[2]);
  const [sid, setId] = useState(window.location.pathname.split("/")[3]);
  const [siteId, setSite] = useState(window.location.pathname.split("/")[4]);
  const [selectedId, setSelectedId] = useState(null);

  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    {
      title: "S. No.",
      render: (_, __, index) => index + 1,
      key: "sno",
    },
    {
      title: "Customer",
      render: (item, record) => <Tag color="#3c40c6">{item.clientName}</Tag>,
      key: "customer",
    },
    {
      title: "Tech. Name",
      render: (item, record) => <Tag color="#1abc9c">{item.techName}</Tag>,
      key: "tech_name",
    },
    {
      title: "Service Date",
      render: (item, record) => <Tag color="#00d8d6">{item.date}</Tag>,
      key: "date",
    },

    {
      title: "Status",
      render: (item, record) => <Tag color="#e74c3c">{item.status}</Tag>,
      key: "status",
    },

    {
      title: "View Details",
      key: "action",
      render: (item, record) => (
        <>
          {item.status === "Verified" ? (
            <>
              {selectedId === item.service ? (
                <Spin indicator={<LoadingOutlined spin />} size="large" />
              ) : (
                <Button
                  type="primary"
                  // href={"/download/" + item.cid + "/" + item.service_guy}
                  onClick={() => {
                    setSelectedId(item.service);
                    downloadFile(item.service);
                  }}
                  style={{ background: "#0be881" }}
                >
                  Download
                </Button>
              )}
            </>
          ) : null}
        </>
      ),
    },
  ]);

  const downloadFile = (sid) => {
    let params = { action: "DOWNLOAD_SERVICE_PDF", sid: sid };

    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        // setSelectedId(null);
        window.open(res.path, "_blank");
      }
    });
  };

  const getMyServices = (cid, sid, siteId) => {
    let params = {
      action: "GET_CLIENT_TECH_SERIVCE",
      cid: cid,
      sid: sid,
      siteId: siteId,
    };
    psApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };

  useEffect(() => {
    getMyServices(cid, sid, siteId);
  }, [window.location]);

  return (
    <Container fluid className={"mainContainer"}>
      <Row>
        <Col md={2} style={{ padding: "0px", margin: "0px" }}>
          <Sidebar to={window.location.pathname} />
        </Col>
        <Col md={10}>
          <Header />

          <Container fluid>
            <Table col={col} data={data} />
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
